import React from 'react'
import PropTypes from 'prop-types'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import TextImageBox from '../../patterns/organisms/TextImage'

const TextImageTastic = ({
    data: {
        title,
        desc,
        positionMode,
        buttonLabel,
        url,
        previewPic,
        reference,
        btnVariant,
        align,
        bgColor,
        media,
        textColor,
        textBGColor,
        secondButtonLabel,
        secondButtonReference,
        secondButtonVariant,
    },
}) => (
    <TextImageBox
        title={title}
        desc={desc}
        media={media}
        previewPic={previewPic}
        url={url}
        positionMode={positionMode}
        label={buttonLabel}
        reference={reference}
        btnVariant={btnVariant}
        align={align}
        bgColor={bgColor}
        textColor={textColor}
        textBGColor={textBGColor}
        secondButtonLabel={secondButtonLabel}
        secondButtonReference={secondButtonReference}
        secondButtonVariant={secondButtonVariant}
    />
)

TextImageTastic.propTypes = {
    data: PropTypes.object.isRequired,
}

export default tastify({ translate: true })(TextImageTastic)

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as PlayButton } from '../../../../icons/sport2000-icons/video-play.svg'

const Video = ({ url, previewPic, title }) => {
    const [videoUrl, setVideoUrl] = useState(url)
    const [play, setPlay] = useState(false)
    const isPreviewPic = !previewPic || (previewPic && !previewPic.media)

    const addDataVideoIframe = () => {
        const autoPlayVideo = isPreviewPic ? '' : '?autoplay=1&muted=1'
        setVideoUrl(`${url}${autoPlayVideo}`)
        setPlay(true)
    }
    const divStyle = () => {
        if (isPreviewPic) {
            return null
        }
        const style = {
            backgroundColor: 'black',
            backgroundImage: `url(${previewPic.media.file})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        }
        return style
    }

    return (
        <div className={'teaser-item-block md:flex-1'}>
            <div className={`teaser-item-wrap ${isPreviewPic ? ' no-preview-image' : ''}`}>
                {!isPreviewPic && !play
                && (
                    <>
                        <div className={'teaser-video-bg'} style={divStyle()} />
                        <div className={'play-button'} onClick={() => addDataVideoIframe()}>
                            <div className={'circle'}>
                                <PlayButton width={100} height={100} />
                            </div>
                        </div>
                    </>
                )}
                <div className={'cms-element-youtube-video'} id={'video-frame1'}>
                    <iframe
                        id={'video-frame'}
                        data-cmp-vendor={'s30'}
                        className={'cmplazyload cms-element-youtube-video__video'}
                        width={'100%'}
                        height={'100%'}
                        data-cmp-src={videoUrl}
                        frameBorder={'0'}
                        allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'}
                        allowFullScreen
                        title={'Embedded youtube'}
                        loading={'lazy'}
                    />
                </div>
            </div>
            {title && <h5 className={'title'}>{title}</h5>}
        </div>
    )
}

Video.propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
    previewPic: PropTypes.object,
}

export default Video

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import FullPageWidthWrapper from '../../molecules/Layout/FullPageWidthWrapper'
import Video from '../../atoms/video'
import Button from '../../atoms/button/Button'
import FrontasticImage from '../../atoms/frontasticImage'

const TextImageBox = ({
    title,
    desc,
    positionMode,
    media,
    align,
    bgColor,
    url,
    previewPic,
    textColor,
    textBGColor,
    label,
    reference,
    btnVariant,
    secondButtonLabel,
    secondButtonReference,
    secondButtonVariant,
}) => {
    const style = {}
    const styleText = {}
    const styleTextBG = {}
    const styleBG = {}

    if (bgColor) {
        style.backgroundColor = bgColor
    }

    if (textBGColor) {
        styleTextBG.backgroundColor = textBGColor
    }

    if (textColor) {
        styleText.color = textColor
    }

    const checkVideoOrImage = () => {
        if (!url && (!media || !media.media)) {
            return null
        }

        if (url) {
            return (
                <Video
                    url={url}
                    previewPic={previewPic}
                />
            )
        }

        return (
            <FrontasticImage
                media={media.media}
                title={title}
                style={{
                    objectFit: 'cover',
                    height: '100%',
                }}
            />
        )
    }

    return (
        <FullPageWidthWrapper
            customClasses={classnames('relative', {
                'py-comp': bgColor,
            })}
            style={style}
        >
            <div className={'o-wrapper'}>
                <div className={'o-grid o-region'}>
                    <div className={'o-cell o-cell--12'}>
                        <div className={'relative'}>
                            <div className={classnames('text-image-component flex flex-wrap', {
                                'md:flex-row-reverse': positionMode === 'left',
                            })}
                            >
                                <div
                                    style={styleTextBG}
                                    className={'content-wrapper flex w-full md:w-1/2 xl:w-1/3'}
                                >
                                    <div className={`group markdown--align__${align}`}>
                                        {title && (
                                            <h3 style={styleText}>{title}</h3>
                                        )}
                                        {desc && (
                                            <p style={styleText}>{desc}</p>
                                        )}
                                        <div className={'content-wrapper--button'}>
                                            {label && (
                                                <Button
                                                    className={classnames('btn mb-4 mr-3', {
                                                        'btn-default': btnVariant === 'default',
                                                        'btn-cta': btnVariant === 'cta',
                                                        'btn-secondary': btnVariant === 'secondary',
                                                    })}
                                                    reference={reference}
                                                >
                                                    {label}
                                                </Button>
                                            )}
                                            {secondButtonLabel && (
                                                <Button
                                                    className={classnames('btn', {
                                                        'btn-default': secondButtonVariant === 'default',
                                                        'btn-cta': secondButtonVariant === 'cta',
                                                        'btn-secondary': secondButtonVariant === 'secondary',
                                                    })}
                                                    reference={secondButtonReference}
                                                >
                                                    {secondButtonLabel}
                                                </Button>
                                            )}
                                        </div>

                                    </div>
                                </div>
                                <div className={'w-full md:w-1/2 xl:w-2/3 relative image-wrapper'}>
                                    <div className="laptop:absolute top-0 left-0 w-full h-full">
                                        {checkVideoOrImage()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </FullPageWidthWrapper>
    )
}

TextImageBox.propTypes = {
    media: PropTypes.objectOf(PropTypes.object).isRequired,
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    bgColor: PropTypes.string,
    textBGColor: PropTypes.string,
    textColor: PropTypes.string,
    previewPic: PropTypes.object,
    positionMode: PropTypes.oneOf(['left', 'right']),
    align: PropTypes.oneOf(['left', 'right', 'center', 'justify']),
    label: PropTypes.string.isRequired,
    reference: PropTypes.object,
    btnVariant: PropTypes.oneOf(['cta', 'secondary', 'default']),
    secondButtonLabel: PropTypes.string.isRequired,
    secondButtonReference: PropTypes.object,
    secondButtonVariant: PropTypes.oneOf(['cta', 'secondary', 'default']),
}

export default TextImageBox
